@media (max-width: 576px) {
  .px-xs-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

@media(min-width:576px){
  .cursor-normal:hover{
      cursor:default !important;
  }
}